export const PictureSlide = ({ slide, backendApi, preloadedImages }) => {
	const { picture_author, picture_url } = slide.row;
	const imageUrl = `${backendApi}/public/file/${picture_url}`;
	const preloadedImageUrl = preloadedImages[imageUrl];

	return (
		<div className='slide picture-slide'>
			<img src={preloadedImageUrl || imageUrl} alt={picture_author} />
			<p>Author: {picture_author}</p>
		</div>
	);
};