import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import './App.css';
import { LeaderboardSlide } from './components/LeaderboardSlide';
import { PictureSlide } from './components/PictureSlide';

export const App = () => {
	const [slides, setSlides] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [preloadedImages, setPreloadedImages] = useState([]);
	const BACKEND_API = process.env.REACT_APP_BACKEND_API;

	const fetchSlides = useCallback(async () => {
		try {
			const response = await axios.get(`${BACKEND_API}/public/slides`);
			if (response.data.status === 'success') {
				setSlides(response.data.payload);
			}
			setIsLoading(false);
		} catch (error) {
			console.error('Error fetching slides', error);
		}
	}, [BACKEND_API]);

	useEffect(() => {
		fetchSlides();
	}, [fetchSlides]);

	useEffect(() => {
		if (!isLoading && slides.length > 0) {
			const slideDuration =
				slides[currentIndex].slide_title === 'Leaderboard' ? 15000 : 15000;
			const timer = setTimeout(() => {
				setCurrentIndex(prevIndex => {
					const nextIndex = (prevIndex + 1) % slides.length;
					if (nextIndex === 0) {
						setPreloadedImages({});
						fetchSlides();
					}
					return nextIndex;
				});
			}, slideDuration);

			return () => clearTimeout(timer);
		}
	}, [currentIndex, slides, isLoading, fetchSlides]);

	useEffect(() => {
		if (!isLoading && slides.length > 0) {
			const nextIndex = (currentIndex + 1) % slides.length;
			const nextSlide = slides[nextIndex];
			if (nextSlide.slide_title !== 'Leaderboard') {
				const imageUrl = `${BACKEND_API}/public/file/${nextSlide.row.picture_url}`;
				if (!preloadedImages[imageUrl]) {
					const img = new Image();
					img.src = imageUrl;
					img.onload = () => {
						setPreloadedImages(prev => ({ ...prev, [imageUrl]: img.src }));
					};
				}
			}
		}
	}, [currentIndex, slides, isLoading, BACKEND_API, preloadedImages]);
	if (isLoading) return <div>Loading...</div>;

	const currentSlide = slides[currentIndex];

	return (
		<div className='presentation'>
			<div className='header'>
				<img src='/logo.svg' alt='logo' />
			</div>
			<div className='presentation-slides'>
        {/* <h5 className='slide-title'>{currentSlide.slide_title}</h5> */}
				<AnimatePresence mode='wait'>
					<motion.div
						key={currentIndex}
						initial={{ opacity: 0, x: -100 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: 100 }}
						transition={{ duration: 1 }}
					>
						{currentSlide.slide_title === 'Leaderboard' ? (
							<LeaderboardSlide slide={currentSlide} />
						) : (
							<PictureSlide
								slide={currentSlide}
								backendApi={BACKEND_API}
								preloadedImages={preloadedImages}
							/>
						)}
					</motion.div>
				</AnimatePresence>
			</div>
		</div>
	);
};

export default App;
