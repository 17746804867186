export const LeaderboardSlide = ({ slide }) => {
	return (
		
		<div className='slide leaderboard'>
			<table>
				<thead>
					<tr>
						<th>#</th>
						<th>Telegram Login</th>
						<th>Points</th>
					</tr>
				</thead>
				<tbody>
					{slide.row.slice(0, 5).map((row, index) => (
						<tr className={`leaderboard-row leaderboard-row-${index}`} key={index}>
							<td>{index + 1}</td>
							<td>{row.telegram_login}</td>
							<td>{row.points}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
